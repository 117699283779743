<template >
  <div name="outcomes">
    <transition name="fade">
      <div class="loading" v-show="loading">
        <span class="fa fa-spinner fa-spin"></span> Loading
      </div>
    </transition>

    <div v-if="errored" class="empty">
      <p>
        We are not able to retrieve this information at the moment, please try
        back later
      </p>
    </div>

    <div
      class="px-auto grid grid-cols-2 gap-10 gap-y-6 bg-white pb-10"
      v-if="componentLoaded"
    >
      <div class="h-screen overflow-y-auto">
        <table v-if="componentLoaded" class="table-wrapper w-full bg-white">
          <tbody>
            <tr
              v-for="pupil in pupils"
              :key="pupil.identifier"
              @click="changePupilSelection(pupil)"
              class="cursor-pointer hover:opacity-50"
            >
              <td width="5%" class="text-lg">
                <i
                  v-bind:class="[
                    isSelectedPupilStyle(pupil)
                      ? 'fas fa-check-circle text-orange-300'
                      : ' far fa-circle text-gray-200',
                  ]"
                ></i>
              </td>
              <td>{{ pupil.name }}</td>
              <td>{{ gradeCount(pupil) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="h-screen overflow-y-auto bg-gray-100 rounded-lg p-4">
        <div class="w-full mx-auto text-center font-bold text-lg text-action-blue mb-2" >
          {{ title }}
        </div>

        <table
          v-if="outcomesLoaded && hasSelectedPupil"
          class="table-wrapper w-full bg-white mx-auto rounded shadow-sm"
        >
          <tbody v-for="(section, index) in outcomeSections" :key="index">
            <tr>
              <td colspan="2" class="text-sm uppercase font-bold">
                {{ section.value }}
              </td>
            </tr>
            <tr
              v-for="(item, index) in outcomesOrderedBySection(section.key)"
              :key="index"
            >
              <td width="55%">
                <span
                  class="
                    txt-xs
                    italic
                    text-gray-500
                    mr-2
                    inline-block
                    float-left
                    h-10
                  "
                  >{{ index + 1 }})</span
                >
                <span class="inline"> {{ item.title }}</span>
            

              <div class="text-right">
                <button
                  v-for="grade in settings.gradeList"
                  :key="grade.key"
                  type="button"
                  @click="setOutcomeGrade(item, grade)"
                  class="
                    border-none
                    col-span-1
                    px-2
                    p-2
                    mt-1
                    w-28
                    rounded
                    lg:mr-2
                    xl:mr-3
                    text-sm
                    font-medium
                    icon
                    hover:text-white
                  "
                  v-bind:class="setStyle(item, grade)"
                >
                  {{ grade.value }}
                </button>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else class="font-bold pl-10 w-full text-center">
          Please select a pupil to set their outcome grades.
        </p>
      </div>
    </div>
  </div>
</template>

  <script>
import { mapGetters } from "vuex";
import { OutcomeService } from "@/services/outcome.service";
import { calendarYearList } from "@/assets/js/utils";

export default {
  name: "outcomes",
  props: {
    course: Object,
    pupils: Array,
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
      settings: "settings",
    }),
    outcomesLoaded() {
      return this.outcomes.length > 0;
    },
    componentLoaded() {
      return this.loading == false;
    },
    title() {
      return this.hasSelectedPupil
        ? "Outcomes for " + this.pupilSelected.name
        : "";
    },
    outcomesOrdered() {
      return this.$orderBy(this.outcomes, ["order", "title"]);
    },
    hasSelectedPupil() {
      return this.pupilSelected != undefined || this.pupilSelected != null;
    },
    outcomeSections() {
      let criteriaList = this.outcomesOrdered.map((o) => o.criteria);
      let distinct = [...new Set(criteriaList)];

      if (distinct.length === 0) {
        return { key: distinct, value: distinct.uppercase() };
      } else {
        return this.settings.assessmentCriteria.filter((a) =>
          distinct.includes(a.key)
        );
      }
    },
  },
  data: function () {
    return {
      errored: false,
      loading: true,
      outcomes: [],
      outcomeMarks: [],
      pupilSelected: null,
    };
  },
  created() {},
  mounted() {
    this.fetchOutcomes();
  },
  methods: {
    valueExists(outcome, grade) {
      let vm = this;
      let result = vm.outcomeMarks.find(
        (o) =>
          o.register_identifier === vm.pupilSelected.identifier &&
          o.outcome_identifier === outcome.identifier
      );
      if (result == undefined) {
        return false;
      } else {
        return result.grade == grade.key;
      }
    },
    setStyle(outcome, grade) {
      if (this.valueExists(outcome, grade)) {
        let icon = grade.key == "greater" ? "fa-star" : "fa-check-circle";
        return grade.background + " text-white font-semibold" + " fas " + icon;
      } else {
        return (
          "text-gray-500 bg-gray-200 far fa-dot-circle " +
          "hover:" +
          grade.background
        );
      }
    },
    isSelectedPupilStyle(pupil) {
      return (
        this.hasSelectedPupil &&
        this.pupilSelected.identifier === pupil.identifier
      );
    },
    changePupilSelection(p) {
      this.pupilSelected = p;
    },
    fetchOutcomes() {
      OutcomeService.getByCourse(this.course.id, this.settings.display.outcomes)
        .then((response) => this.buildOutcomes(response.data))
        .catch((err) => {
          console.log("Outcomes " + err);
          this.errored = true;
        });
    },
    buildOutcomes(data) {
      this.outcomes = data;

      // fetch grades
      OutcomeService.getCourseGrades(this.course.id)
        .then((response) => (this.outcomeMarks = response.data))
        .catch((err) => {
          console.log("Grades " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    outcomesOrderedBySection(section) {
      return this.outcomesOrdered.filter((o) => o.criteria === section);
    },
    getSectionTitle(section) {
      return section;
    },
    gradeCount(pupil) {
      return this.outcomeMarks.filter(
        (o) => o.register_identifier === pupil.identifier
      ).length;
    },
    setOutcomeGrade(outcome, grade) {
      let vm = this;
      let result = vm.outcomeMarks.find(
        (o) =>
          o.register_identifier === vm.pupilSelected.identifier &&
          o.outcome_identifier === outcome.identifier
      );

      if (result != undefined) {

        if (result.grade === grade.key) {
          OutcomeService.deleteGrade(result)
            .then((response) => {
              vm.outcomeMarks = vm.outcomeMarks.filter(
                (o) => o.identifier !== result.identifier
              );
            })
            .catch((err) => {
              console.log("GRADE DELETE error " + err);
            });
        } else {
          result.grade = grade.key;
          OutcomeService.updateGrade(result).catch((err) => {
            this.completion(err);
          });
        }
      } else {
        let calendarYear = calendarYearList().find((y) => y.current === true);

        let payload = {
          course_id: vm.course.id,
          register_identifier: vm.pupilSelected.identifier,
          calendar_schoolyear: calendarYear.value,
          grade: grade.key,
          outcome_identifier: outcome.identifier,
        };

        OutcomeService.addGrade(payload)
          .then((response) => {
            payload.identifier = response.data.identifier;

            vm.outcomeMarks.push(payload);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>

</style>