import { SchoolService, BaseService } from "@/services/school.service";

const OutcomeService = {
    getPublic () {
        return BaseService.getAll("outcomes")
    },

    getPrivate() {
        return SchoolService.getAll("outcomes?type=private&state=all")
    },

    getByCourse(courseId, type) {
    	let endpoint = type == 'custom' ? 'outcomes?type=member' : 'outcomes'
        return SchoolService.getAll("courses/" + courseId + "/" + endpoint)
    },

    add(payload) {
        return SchoolService.create("outcomes", payload)
    },

    update(object) {
        return SchoolService.update("outcomes", object.identifier, object)
    },

    // UNITS
    addUnits(object, payload) {
        return SchoolService.create( "outcomes/" + object.identifier + "/courses", payload)
    },

    // GRADES
    addGrade(payload) {
    	return SchoolService.create("outcomegrades", payload)
    },

    updateGrade(result) {
    	return SchoolService.update("outcomegrades", result.identifier, result)
    },


    deleteGrade(result) {
    	return SchoolService.delete("outcomegrades", result.identifier, null)
    },

    getCourseGrades(courseId) {
    	return SchoolService.getAll("courses/" + courseId + "/outcomegrades")
    }

     
}

export { OutcomeService };